
import React from 'react' 
import Join from '../../../components/Page/Join';


const JoinPage = () => {

  return (
    <>
      <Join />
    </>
  )
}

export default JoinPage;